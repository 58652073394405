/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import DescriptionIcon from "@material-ui/icons/Description";
import LanguageIcon from "@material-ui/icons/Language";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">USERS</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        {user.role === "admin" && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/admins",
              false
            )} ${getMenuItemActive("/edit-admin", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admins">
              <span className="menu-icon">
                <AssignmentIndIcon />
              </span>
              <span className="menu-text">Administrators</span>
            </NavLink>
          </li>
        )}

        <li className="menu-section">
          <h4 className="menu-text">INFO</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        {user.role === "admin" && (
          <>
            <li
              className={`menu-item ${getMenuItemActive(
                "/families",
                false
              )} ${getMenuItemActive("/edit-family", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/families">
                <span className="menu-icon">
                  <DescriptionIcon />
                </span>
                <span className="menu-text">Families</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/languages",
                false
              )} ${getMenuItemActive("/edit-language", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/languages">
                <span className="menu-icon">
                  <LanguageIcon />
                </span>
                <span className="menu-text">Languages</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/texts",
                false
              )} ${getMenuItemActive("/edit-text", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/texts">
                <span className="menu-icon">
                  <DescriptionIcon />
                </span>
                <span className="menu-text">Texts</span>
              </NavLink>
            </li>
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
